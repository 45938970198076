<template>
  <div v-if="!isSupport" class="full-width full-height position-relative">
    <v-overlay :value="!isSupport">
      <div class="not-support-map">
        <p class="">
          Trình duyệt của bạn không hỗ trợ hiển thị bản đồ, vui lòng đổi trình
          duyệt hoặc cập nhật bản mới để xem.
        </p>
      </div>
    </v-overlay>
  </div>
  <div class="map-container" v-else>
    <div class="main-container">
      <div class="map-viewer">
        <div ref="mapContainer" class="map-content"></div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import GestureHandling from "@geolonia/mbgl-gesture-handling";
export default {
  props: {
    mapboxAccessToken: {
      type: String,
      default: "",
    },

    initOptions: {
      type: Object,
      default: () => ({
        center: [105.85121154785156, 21.02682813712425],
        zoom: 7,
        maxZoom: 22,
        attributionControl: false,
        zoomControl: false,
      }),
    },
    gestureHandling: Boolean,
  },

  data() {
    return {
      isSupport: true,
      loaded: false,
      map: null,
    };
  },

  created() {
    this.isSupport = mapboxgl.supported();
    if (!mapboxgl.supported()) {
      alert("Your browser does not support Mapbox GL");
    }
    mapboxgl.accessToken = this.mapboxAccessToken;
  },

  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },

  destroyed() {
    this.destroy();
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  methods: {
    init() {
      const config = {
        container: this.$refs.mapContainer,
        style: {
          version: 8,
          name: "Empty",
          metadata: {},
          sprite: "https://tiles.skymapglobal.vn/styles/basic/sprite",
          glyphs: "https://tiles.skymapglobal.vn/fonts/{fontstack}/{range}.pbf",
          sources: {},
          layers: [],
        },
        locale: {
          "FullscreenControl.Enter": this.$t("map.FullscreenControl.Enter"),
          "FullscreenControl.Exit": this.$t("map.FullscreenControl.Exit"),
          "GeolocateControl.FindMyLocation": this.$t(
            "map.GeolocateControl.FindMyLocation"
          ),
          "GeolocateControl.LocationNotAvailable": this.$t(
            "map.GeolocateControl.LocationNotAvailable"
          ),
          "LogoControl.Title": this.$t("map.LogoControl.Title"),
          "NavigationControl.ResetBearing": this.$t(
            "map.NavigationControl.ResetBearing"
          ),
          "NavigationControl.ZoomIn": this.$t("map.NavigationControl.ZoomIn"),
          "NavigationControl.ZoomOut": this.$t("map.NavigationControl.ZoomOut"),
          "ScaleControl.Feet": "ft",
          "ScaleControl.Meters": "m",
          "ScaleControl.Kilometers": "km",
          "ScaleControl.Miles": "mi",
          "ScaleControl.NauticalMiles": "nm",
        },
        pitchWithRotate: false,
        doubleClickZoom: false,
        dragRotate: false,
        ...this.initOptions,
      };
      if (window.config.maxBounds && window.config.maxBounds.length > 0) {
        config.maxBounds = window.config.maxBounds;
      }
      this.map = new mapboxgl.Map(config);
      this.map.touchZoomRotate.disableRotation();
      this.map.once("load", () => {
        this.loaded = true;
        // Emit for parent component
        this.$emit("map-loaded", this.map);
      });
      if (this.gestureHandling) {
        const options = {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          textColor: "#ffffff",
          textMessage: "Sử dụng ctrl + scroll để phóng to",
          textMessageMobile: "Sử dụng 2 ngón để di chuyển bản đồ",
          timeout: 2000,
          modifierKey: "ctrl",
        };
        new GestureHandling(options).addTo(this.map);
      }
    },
    destroy() {
      this.map.remove();
      this.map = null;
    },
  },
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #f7f5f2;
}
.not-support-map {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-items: center;
}

.main-container {
  position: relative;
  display: flex;
  flex-flow: column;
  flex: 1;
  min-height: 0;
  min-width: 0;
  z-index: 0;
}

.map-viewer {
  position: relative;
  flex: 1;
  min-height: 0;
  min-width: 0;
  z-index: 0;
}

.map-content {
  width: 100%;
  height: 100%;
}
</style>

<style>
/* Group Control */
.map-container .mapboxgl-ctrl .mapboxgl-ctrl:first-child {
  margin: 0;
}

.map-container .mapboxgl-ctrl .mapboxgl-ctrl:not(:first-child) {
  margin: 10px 0 0 0;
}

/* Map Canvas */
.mapboxgl-canvas:hover,
.mapboxgl-canvas:focus,
.mapboxgl-canvas:active {
  border: none;
  outline: none;
}
</style>
